import { AnalyticsBrowser } from '@segment/analytics-next';
import { ENV } from '../config/environment';
import { browserNameAndVersion } from '../utils/browser';
import { AuthState, authState, speechifyMobileUser } from '../stores/user';
import { get } from 'svelte/store';

export class AnalyticsService {
  private static prefix = 'dubbing_';
  private static segment = AnalyticsBrowser.load(
    {
      writeKey: ENV.segmentWriteKey,
      cdnURL: ENV.segmentProxyCdnHost,
    },
    {
      integrations: {
        'Segment.io': {
          apiHost: ENV.segmentProxyApiHost,
        },
      },
    },
  );

  static getBrowserDetails() {
    const [browserName, browserVersion] = browserNameAndVersion('/').split('/');
    return {
      browserName,
      browserVersion,
      webpage: window.location.href,
      hostname: window.location.hostname,
    };
  }

  // We don't want to block the user from using the app if the analytics event fails
  // thus please don't await this function
  static async logAnalyticsEvent(name: string, properties: any = {}) {
    try {
      const browserDetails = this.getBrowserDetails();
      this.segment.track(
        `${this.prefix}${name}`,
        {
          ...properties,
          ...browserDetails,
        },
        {
          protocols: {
            sourceId: ENV.segmentSourceId,
          },
        },
      );
    } catch {}
  }

  static identifyWithEmail(uid: string, email: string) {
    this.segment.identify(uid, {
      email,
    });
  }
}

authState.subscribe((state) => {
  if (state === AuthState.LOGGED_IN) {
    const { uid, email } = get(speechifyMobileUser);
    AnalyticsService.identifyWithEmail(uid, email);
  }
});
