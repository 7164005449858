export const ENV = {
  firebaseSDK: {
    videoStudioConfig: {
      apiKey: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_DATABASE_URL,
      projectId: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env
        .VITE_VIDEO_STUDIO_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_VIDEO_STUDIO_FIREBASE_APP_ID,
    },
    speechifyMobileConfig: {
      apiKey: import.meta.env.VITE_SPEECHIFY_MOBILE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_SPEECHIFY_MOBILE_FIREBASE_AUTH_DOMAIN,
      databaseURL: import.meta.env.VITE_SPEECHIFY_MOBILE_FIREBASE_DATABASE_URL,
      projectId: import.meta.env.VITE_SPEECHIFY_MOBILE_FIREBASE_PROJECT_ID,
      storageBucket: import.meta.env
        .VITE_SPEECHIFY_MOBILE_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: import.meta.env
        .VITE_SPEECHIFY_MOBILE_FIREBASE_MESSAGING_SENDER_ID,
      appId: import.meta.env.VITE_SPEECHIFY_MOBILE_FIREBASE_APP_ID,
    },
  },
  authApiUrl: import.meta.env.VITE_AUTH_API_URL,
  apiUrl: import.meta.env.VITE_API_URL as string,
  wsUrl: import.meta.env.VITE_WS_URL as string,
  paymentServerUrl: import.meta.env.VITE_PAYMENT_SERVER_URL as string,
  foyerUrl: import.meta.env.VITE_FOYER_URL as string,
  speechifyCvlUrl: import.meta.env.VITE_SPEECHIFY_CVL_API_URL as string,
  firebaseDynamicLinkDomain: import.meta.env
    .VITE_FIREBASE_DYNAMIC_LINK_DOMAIN as string,
  segmentWriteKey: import.meta.env.VITE_SEGMENT_WRITE_KEY as string,
  segmentProxyCdnHost: import.meta.env.VITE_SEGMENT_PROXY_CDN_HOST as string,
  segmentProxyApiHost: import.meta.env.VITE_SEGMENT_PROXY_API_HOST as string,
  segmentSourceId: import.meta.env.VITE_SEGMENT_SOURCE_ID as string,
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY as string,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN as string,
  personalizedVoiceListUrl: import.meta.env
    .VITE_PERSONALIZED_VOICE_LIST_URL as string,
  configCatApiKey: import.meta.env.VITE_CONFIG_CAT_APIKEY as string,
  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID as string,
  dubbingUrl: import.meta.env.VITE_DUBBING_URL as string,
  voicesServer: import.meta.env.VITE_VOICE_SERVER_URL,
  phonemizerApiUrl: import.meta.env.VITE_PHONEMIZER_API_URL as string,
  studioUrl: import.meta.env.VITE_STUDIO_URL as string,
  catalogUrl: import.meta.env.VITE_CATALOG_URL as string,
  isProd: import.meta.env.VITE_VERCEL_ENV === ('production' as boolean),
  trial: {
    basic: import.meta.env.VITE_PUBLIC_BASIC_TRIAL_PLAN as string,
    pro: import.meta.env.VITE_PUBLIC_PRO_TRIAL_PLAN as string,
  },
};
